// A function to process the data should be divided to represent on an axis. The start ,end, steps and each individual step value all being rounded off to nearest 10,50,100,500...
var getDivisions = function (min, max, fromZero, steps) {
    var difference = fromZero ? max : max - min;
    var isSpecialCase = !(difference >= 1 || difference == 0);
    var originalDifference = difference;
    var multiplier = 1;
    if (isSpecialCase) {
        multiplier = Math.pow(10, difference.toString().split(".")[1].length);
        difference = difference * multiplier;
        min = min * multiplier;
        max = max * multiplier;
    }
    var power;
    power = (difference.toFixed(0)).toString().length;
    var tenPower = Math.pow(10, power);
    var tenPowerMinusOne = Math.pow(10, power - 1);
    var truePower;
    if (0.2 * tenPower >= difference && difference >= (0.2 * tenPowerMinusOne) + 1) {
        truePower = power - 1;
    }
    else if (difference == 1) {
        truePower = power - 1;
    }
    else {
        truePower = power;
    }
    var trueTenPower = Math.pow(10, truePower);
    var stepsTemp;
    if (trueTenPower * 0.2 < difference && trueTenPower * 0.5 >= difference) {
        stepsTemp = trueTenPower / 20;
    }
    else if (trueTenPower * 0.5 < difference && trueTenPower * 0.75 >= difference) {
        stepsTemp = trueTenPower / 10;
    }
    else if (trueTenPower * 0.75 < difference && trueTenPower * 1 >= difference) {
        stepsTemp = trueTenPower / 10;
    }
    else if (trueTenPower * 1 < difference && trueTenPower * 2 >= difference) {
        stepsTemp = trueTenPower / 5;
    }
    // console.log(trueTenPower, min, max, stepsTemp)
    var stepsToReturn = steps || stepsTemp;
    var start = (fromZero) ? 0 : Math.floor(min / stepsToReturn) * stepsToReturn;
    var end = Math.ceil(max / stepsToReturn) * stepsToReturn;
    start = start / multiplier;
    end = end / multiplier;
    stepsToReturn = stepsToReturn / multiplier;
    // if(valueType == "integer"){
    //     if(stepsToReturn < 1){
    //         stepsToReturn = 1;
    //     }else if(stepsToReturn > 1 && stepsToReturn%parseInt(stepsToReturn,10) != 0 ){
    //         let remainder = stepsToReturn%parseInt(stepsToReturn,10);
    //         stepsToReturn =  remainder < 0.5 ? remainder : parseInt(stepsToReturn,10) + 1;
    //     }
    // } 
    difference = originalDifference;
    min = min / multiplier;
    max = max / multiplier;
    var values = [];
    var temp = start;
    function isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }
    var minSplit = min.toString().split(".")[1];
    var maxSplit = max.toString().split(".")[1];
    var differenceSplit = difference.toString().split(".")[1];
    var stepsSplit = stepsToReturn.toString().split(".")[1];
    var toFixed;
    if (!isSpecialCase && difference != 1) {
        toFixed = Math.max.apply(Math, [
            differenceSplit ? differenceSplit.length : 0,
            maxSplit ? maxSplit.length : 0,
            minSplit ? minSplit.length : 0,
            stepsSplit ? stepsSplit.length : 0,
        ]);
    }
    else {
        toFixed = Math.max.apply(Math, [
            differenceSplit ? differenceSplit.length : 0,
            maxSplit ? maxSplit.length : 0,
            minSplit ? minSplit.length : 0,
            stepsSplit ? stepsSplit.length : 0,
        ]);
    }
    // to avoid float issues
    var multiplierToAvoidFloatingNumberIssues = 1;
    if (stepsToReturn < 10 && stepsToReturn.toString().indexOf(".") >= 0) {
        multiplierToAvoidFloatingNumberIssues *= Math.pow(10, stepsToReturn.toString().split(".")[1].length);
        start *= multiplierToAvoidFloatingNumberIssues;
        temp *= multiplierToAvoidFloatingNumberIssues;
        stepsToReturn *= multiplierToAvoidFloatingNumberIssues;
        end *= multiplierToAvoidFloatingNumberIssues;
    }
    while (temp <= end) {
        temp = isInt(temp) ? temp : Number(temp.toFixed(toFixed)) === 0 ? temp : Number(temp.toFixed(toFixed));
        values.push({ value: (temp / multiplierToAvoidFloatingNumberIssues) });
        temp += stepsToReturn;
    }
    var returnObj = {
        start: start / multiplierToAvoidFloatingNumberIssues,
        end: end / multiplierToAvoidFloatingNumberIssues,
        steps: stepsToReturn / multiplierToAvoidFloatingNumberIssues,
        values: values
    };
    return returnObj;
};
export { getDivisions as g };
